import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { GetDocumentTypeDto } from '../models/doc-types.model';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypesApiService extends ApiService {
  constructor() {
    super();
  }

  public getDocumentTypes$(): Observable<GetDocumentTypeDto[]> {
    return this.get('/documentTypes');
  }

  createDocumentType$(name: string): Observable<GetDocumentTypeDto> {
    return this.put(`/documentTypes/${name}`);
  }

  updateDocumentType$(name: string, id: string): Observable<GetDocumentTypeDto> {
    return this.post(`/documentTypes/${id}`, name);
  }

  getDocumentTypeById$(id: string): Observable<GetDocumentTypeDto> {
    return this.get(`/documentTypes/${id}`);
  }

  deleteDocumentType$(uniqueId: string): Observable<any> {
    return this.delete(`/documentTypes/${uniqueId}`);
  }

  batchDeleteDocumentType$(ids: string[]): Observable<any> {
    return this.post(`/documentTypes/batch-delete`, ids);
  }
}

export const apiDocumentTypesProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiDocumentTypes.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiDocumentTypes.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiDocumentTypes.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiDocumentTypes.scopes.write],
  },
];
